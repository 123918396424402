/* gives higher priority and make sure it will apply only where both classname are available */
.signupbutton.MuiButton-root {
  margin-top: 1rem;
  width: 40%;
  color: white;
  flex-direction: row-reverse;
}

.sidebackground {
  height: 100%;
  width: 100%;
}

.signupinputcontainer.MuiGrid-root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.signupinputbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.signuplogo {
  padding-left: 50px;
}

@media (max-width: 900px) {
  .sidebackground {
    display: none;
  }
}
