.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.dot {
    width: 8px;
    height: 8px;
    margin: 0 3px;
    background-color: black;
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
}

.dot:nth-child(1) {
    animation-delay: -0.3s;
}

.dot:nth-child(2) {
    animation-delay: -0.15s;
}

@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
