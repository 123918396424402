@tailwind base;
@tailwind components;
@tailwind utilities;


  .selected {
    background-color: blue;
  }
  .ant-btn:hover {

    /* Set the background color to transparent */
  
    color: #fff; /* Set the text color (if needed) */
  
    /* Add any other styles as needed */
  
  }

  :where(.css-dev-only-do-not-override-pr0fja).ant-btn-default:not(:disabled):not(

    .ant-btn-disabled

  ):hover {

  color: #fff;

}

::-webkit-scrollbar-thumb {
  background: #bcbcbc;
  border-radius: 6px;
}
 
::-webkit-scrollbar-track {
  background-color: #ffffff;
}
 
::-webkit-scrollbar-thumb:hover {
  background: #838383;
}
 
::-webkit-scrollbar-thumb:active {
  background: #888;
}
 
::-webkit-scrollbar-thumb:disabled {
  background: #ccc;
}
 
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
}