* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.topnav {
  overflow: hidden;
  margin-top: 3rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.project__search {
  width: 30rem;
  padding: 20px;
  border: none;
  outline: none;
  border-radius: 50px;
  background-color: rgb(237, 237, 237);
}

.project__search:active {
  border: none;
  outline: none;
}

.topnav input[type="text"] {
  float: right;
  padding: 6px;
  margin-top: 8px;
  margin-right: 16px;
  border: none;
  font-size: 17px;
}

.report-button {
  background: #4e79fa !important;
  color: white;
  padding: 0.8rem 1rem !important;
  margin-right: 2px;
}

.report-button:hover {
  background-color: #4e79fa; /* Maintain blue background on hover */
}

.styleActionButtons {
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  font-weight: 500 !important;
  font-size: 1rem !important;
  box-shadow: none;
}

.cancel-button-style {
  color: #4e79fa !important;
  background-color: white !important;
  border-radius: 8px;
  width: 40%;
  transition: background-color 0.3s, color 0.3s !important;
}

.cancel-button-style:hover {
  background-color: #4e79fa !important;
  color: white !important;
}

.apply-button-style {
  color: white !important;
  background-color: #4e79fa !important;
  border-radius: 8px;
  width: 40%;
  /* transition: background-color 0.3s, color 0.3s; */
}

.apply-button-style:hover {
  background-color: white !important;
  color: #4e79fa !important;
}

.filter-filter-button {
  border-radius: 8px !important;
  padding: 5px 15px !important;
  width: 109px;
}

.filter-reset-button {
  border-radius: 8px !important;
  padding: 5px 15px !important;
  width: 109px;
}

@media screen and (max-width: 600px) {
  .topnav a,
  .topnav input[type="text"] {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
}
