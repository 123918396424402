.no-data-found {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.headingOn {
  color: rgb(27, 175, 27);
  font-size: 20px;
  font-weight: 700 !important;
  text-transform: capitalize;
}
.headingOff {
  color: rgb(255, 140, 0);
  font-size: 20px;
  font-weight: 700 !important;
  text-transform: capitalize;
}
