* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.topnav {
  overflow: hidden;
  margin-top: 3rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.developer__search {
  width: 30rem;
  padding: 20px;
  border: none;
  outline: none;
  border-radius: 50px;
  background-color: rgb(237, 237, 237);
}

.developer__search:active {
  border: none;
  outline: none;
}

/* .topnav a.active {
  background-color: #2196F3; 
  color: white;
} */
.topnav input[type="text"] {
  float: right;
  padding: 6px;
  margin-right: 16px;
  border: none;
  font-size: 17px;
}

@media screen and (max-width: 600px) {
  .topnav a,
  .topnav input[type="text"] {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
}
/* .css-qehn2n-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: 10px !important;
  min-height: 0px !important;
} */

/* .css-qehn2n-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 12px !important;
  padding: 6.5px 25px 1.5px 7px;
  height: 20px !important;
} */

/* .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px !important;
} */
table {
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  margin: 8px;
}
th:first-child {
  text-align: center;
  /* background-color: #000000e7; */
  padding: 0.7rem;
  color: white;
}
td:first-child {
  text-align: center;
  padding: 0.3rem;
}
th:last-child {
  /* background-color: #000000e7; */
  color: white;
}
tr:nth-child(even) {
  /* background-color: #f1f1f1; */
}
#emptydata {
  text-align: right;
  margin-top: 1.1rem;
  position: relative;
  right: 4rem;
}
tr:not(:first-child):hover {
  /* your styles here */
  background-color: #e9e9e9;
  cursor: pointer;
  padding: 3rem;
}
#btn-close {
  float: right;
  position: absolute;
  right: 1rem;
  padding-top: 0.9rem;
}
#modalContaner {
  padding: 0px 0px 50px 0px;
  width: 35rem;
}
